import React from 'react';
import { graphql } from 'gatsby';
import Feed from '../components/Feed';
import Layout from '../components/Layout';
import Page from '../components/Page';
import RightSidebar from '../components/RightSidebar';
import Sidebar from '../components/Sidebar';

const CategoryTemplate = ({ data, pageContext }) => {
  const {
    title: siteTitle,
    subtitle: siteSubtitle,
    logo,
  } = data.site.siteMetadata;

  const { category } = pageContext;

  const { edges } = data.allMarkdownRemark;
  const pageTitle = `${category} - ${siteTitle}`;

  return (
    <Layout title={pageTitle} description={siteSubtitle}>
      <Sidebar />
      <Page logo={logo} title={category}>
        <Feed edges={edges} showCategory={false} />
      </Page>
      <RightSidebar />
    </Layout>
  );
};

export const query = graphql`
  query CategoryPage($category: String) {
    site {
      siteMetadata {
        title
        subtitle
        logo {
          title
          subtitle
          path
          width
          height
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: {
          category: { eq: $category }
          template: { eq: "post" }
          draft: { eq: false }
        }
      }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          fields {
            categorySlug
            slug
          }
          frontmatter {
            order
            created_at
            modified_at
            description
            category
            title
          }
        }
      }
    }
  }
`;

export default CategoryTemplate;
