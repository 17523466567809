import React from "react";
import moment from "moment";
import { Link } from "gatsby";
import {
  feed__item,
  feed__itemTitleLink,
  feed__itemTitle,
  feed__itemMeta,
  feed__itemMetaTime,
  feed__itemMetaCategoryLink,
  feed__itemDescription,
  feed__itemReadmore,
} from "./Feed.module.scss";

const Feed = ({ edges, showCategory }) => {
  return (
    <div>
      {edges.map((edge) => (
        <div className={feed__item} key={edge.node.fields.slug}>
          <h2 className={feed__itemTitle}>
            <Link
              className={feed__itemTitleLink}
              to={`/${edge.node.fields.slug}`}
            >
              {edge.node.frontmatter.title}
            </Link>
          </h2>
          <p className={feed__itemMeta}>
            <time
              className={feed__itemMetaTime}
              dateTime={moment(edge.node.frontmatter.date).format(
                "MMMM D, YYYY",
              )}
            >
              最終更新 {moment(edge.node.frontmatter.modified_at).format("L")}
            </time>
            <span>
              {showCategory ? (
                <Link
                  to={edge.node.fields.categorySlug}
                  className={feed__itemMetaCategoryLink}
                >
                  &nbsp;{edge.node.frontmatter.category}
                </Link>
              ) : (
                <></>
              )}
            </span>
          </p>
          <p className={feed__itemDescription}>
            {edge.node.frontmatter.description}
          </p>
          <Link className={feed__itemReadmore} to={`/${edge.node.fields.slug}`}>
            続きを読む
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Feed;
